<template>
  <div class="change-password">
    <heads title="修改密码"></heads>
    <div class="finish-content">
      <van-field
        placeholder="请输入手机号"
        type="digit"
        v-model="phoneNumber"
        maxlength="11"
        @blur="blurPhone"
        :readonly="codeContent=='获取验证码'?false:true"
      />
      <div class="get-code">
        <van-field
          type="digit"
          maxlength="6"
          placeholder="输入验证码"
          v-model="code"
        />
        <div
          :class="`${sendCodeFlag ? 'blue-text' : ''} code-text`"
          @click="getCode"
        >
          {{codeContent}}{{codeContent!='获取验证码'?'s后重发':''}}
        </div>
      </div>
      <van-field
        class="transpranent-input"
        :type="showPassword?'text':'password'"
        placeholder="请输入密码"
        v-model="password"
        :right-icon="showPassword?require('@/assets/images/blue_eyes.png'):require('@/assets/images/blue_eyes_display.png')"
        @click-right-icon="clickRightIcon"
      />
      <van-button
        :class="`${phoneNumber&&code&&password?'sure-btn ':'sure-btn btn-opacity'}`"
        @click="save"
      >确认</van-button>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
export default {
  components: {
    heads: Header
  },
  data() {
    return {
      phoneNumber: null,
      code: null,
      password: null,
      codeContent: "获取验证码",
      sendCodeFlag: false,
      showPassword: false
    };
  },
  methods: {
    blurPhone() {
      this.sendCodeFlag = false;
      if (!/^1[3456789]\d{9}$/.test(this.phoneNumber) && this.phoneNumber) {
        this.$toast("手机号码有误，请重填");
        return false;
      }
      this.sendCodeFlag = true;
    },
    getCode() {
      // 手机号&&手机类型
      if (!this.phoneNumber) {
        this.$toast("请输入手机号");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phoneNumber)) {
        this.$toast("请输入正确手机号");
        return;
      }
      this.codeContent = 60;
      this.codeContentTimer2 = setInterval(() => {
        this.codeContent = this.codeContent - 1;
        if (this.codeContent < 1) {
          clearInterval(this.codeContentTimer2);
          this.codeContent = "获取验证码";
        }
      }, 1000);
    },
    clickRightIcon() {
      this.showPassword = !this.showPassword;
    },
    save() {
      if (!this.phoneNumber) {
        this.$toast("请输入手机号");
        return;
      }
      if (!this.code) {
        this.$toast("请输入验证码");
        return;
      }
      if (!this.password) {
        this.$toast("请输入密码");
        return;
      }
      
      this.$router.back();
    }
  },
  destroyed() {
    clearInterval(this.codeContentTimer2);
  }
};
</script>

<style lang="scss" scoped>
.change-password {
  .blue-text {
    color: #3971ff !important;
  }
}
</style>
